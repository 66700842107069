import { Avatar, Box, Checkbox, Flex, Text } from "@mantine/core";
import { IconPhoto } from "@tabler/icons-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { NetworkBusinessApi } from "../../apis";
import { PopoverText } from "../../components/popover-text/popover-text";
import { ColumnType, ITableRef, Table } from "../../components/table";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { renderName } from "../../utils/render-name";

export function PaymentTermManageList({
  values,
  setFieldValue,
  refTable,
}: {
  values: any;
  setFieldValue: (e1: string, e2: any) => void;
  refTable: ITableRef | any;
}) {
  const { paymentTermConfigTypes, businessStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const { businessType } = useSelector((state: { auth: IAuth }) => state.auth);

  const [filters] = useState({ query: "" });

  const columns = useHeader({
    businessStatus,
    businessTypes,
    paymentTermConfigTypes,
    values,
    setFieldValue,
    isSelect: businessType === "SUPPLIER",
  });

  return (
    <div>
      {businessType === "SUPPLIER" && (
        <Text size={"sm"} c="dimmed">
          Сонгогдсон нийт бизнес : {values?.businessIds?.length ?? 0}
        </Text>
      )}
      <Table ref={refTable} name="network.invitation.received" columns={columns} filters={filters} loadData={(data) => NetworkBusinessApi.list(data!)} />
    </div>
  );
}

const businessTypes = [
  {
    code: "SUPPLIER",
    name: "Нийлүүлэгч",
  },
  {
    code: "BUYER",
    name: "Худалдан авагч",
  },
];

const useHeader = ({
  paymentTermConfigTypes,
  businessStatus,
  businessTypes,
  values,
  setFieldValue,
  isSelect,
}: {
  paymentTermConfigTypes: IReference[];
  businessStatus: IReference[];
  businessTypes: IReference[];
  values: any;
  isSelect: boolean;
  setFieldValue: (e1: string, e2: any) => void;
}): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: 30,
  },
  {
    title: "",
    renderTitle(record) {
      return isSelect ? (
        <Checkbox
          disabled={!isSelect}
          checked={values?.businessIds?.length === record?.rows.length}
          onChange={(e) => {
            if (!e.target.checked) {
              setFieldValue("businessIds", []);
            } else
              setFieldValue(
                "businessIds",
                record?.rows?.map((item: any) => item.id),
              );
          }}
        />
      ) : (
        ""
      );
    },
    render: (record) => {
      return isSelect ? (
        <Checkbox
          disabled={!isSelect}
          onChange={() => {
            if (values?.businessIds?.some((som: string) => som === record.id)) {
              setFieldValue("businessIds", [...values?.businessIds?.filter((fil: string) => fil !== record.id)]);
            } else setFieldValue("businessIds", [...(values?.businessIds ?? []), record?.id]);
          }}
          checked={values?.businessIds?.some((som: string) => som === record.id) ?? false}
        />
      ) : (
        ""
      );
    },
  },
  {
    title: "Бизнесийн нэр",
    render: (record) => (
      <Box>
        <Text size="sm" w="max-content">
          {record?.profileName ?? "-"}
        </Text>
        <Text c="indigo" size="sm" w="max-content">
          #{record?.refCode ?? "-"}
        </Text>
      </Box>
    ),
  },
  {
    title: "Партнерийн нэр",
    render: (record) =>
      record?.partner ? (
        <Flex align="center" gap="sm">
          <Avatar src={record?.partner?.logo} radius="xs">
            <IconPhoto />
          </Avatar>
          <Box>
            <Text size="sm" w="max-content">
              {record?.partner?.businessName ?? "-"}
            </Text>
            <Text c="indigo" size="sm" w="max-content">
              #{record?.partner?.refCode ?? "-"}
            </Text>
          </Box>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "ТТД",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.regNumber ?? "-"}
      </Text>
    ),
  },
  {
    title: "Харилцагчийн нэр",
    dataIndex: "image",
    render: (record) =>
      record?.coBusinessStaff ? (
        <Flex align="center" gap="sm">
          <Avatar src={record?.coBusinessStaff?.avatar} />
          <Box>
            <Text size="sm" w="max-content">
              {renderName(record?.coBusinessStaff)}
            </Text>
            <Text c="gray" size="sm">
              {record?.coBusinessStaff?.email ?? "-"}
            </Text>
          </Box>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "Төлбөрийн нөхцөл",
    dataIndex: "image",
    render: (record) => {
      if (record.paymentTerm)
        return (
          <Box>
            <Text c="violet" size="sm">
              #{record.paymentTerm?.refCode}
            </Text>
            <PopoverText children={record?.paymentTerm?.description} />
          </Box>
        );
      return "-";
    },
  },
  {
    title: "Ангилал",
    dataIndex: "name",
    width: "200px",
    render: (record) =>
      record?.classificationCategory ? (
        <Box>
          <Text size="sm" w="max-content">
            {record?.classificationCategory?.name ?? "-"}
          </Text>
          <Text c="indigo" size="sm" w="max-content">
            #{record?.classificationCategory?.refCode ?? "-"}
          </Text>
        </Box>
      ) : (
        "-"
      ),
  },
  {
    title: "Зэрэглэл",
    dataIndex: "name",
    render: (record) =>
      record?.classificationPriority ? (
        <Box>
          <Text size="sm">{record?.classificationPriority?.name ?? "-"}</Text>
          <PopoverText>{record?.classificationPriority?.description ?? "-"}</PopoverText>
        </Box>
      ) : (
        "-"
      ),
  },
];
